import React from "react";
import {Card} from 'antd';

import DUIImg from '../../images/services/DUI.png';
import ServiceTemplateCascade from "./ServiceTemplateCascade";

const Items = [
  {key: 1, title: 'What Do I Need to Know About My First DWI or DUI?', preview: <div>
    <div className="BodyGreyLeft mb16">A DWI or DUI in Missouri is a very serious offense. A conviction can carry jail time and long-
term life consequences. Possible punishments include jail time, heavy fines, loss of license, 
impact to your insurance, and employment troubles.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    A DWI or DUI in Missouri is a very serious offense. A conviction can carry jail time and long-
term life consequences. Possible punishments include jail time, heavy fines, loss of license, 
impact to your insurance, and employment troubles.
<br/><br/>
Hiring a lawyer to help you handle your DWI or DUI will allow you to get the best possible 
outcome. TicketTamer provides a free initial case review, and then works with the prosecutor to 
get the charge reduced down to a less severe offense. 
<br/><br/>

You likely will still be fined, may be put on probation, be required to take a class or community 
service. But it is much better than going to court alone and hoping for the best. That probably 
won’t happen. TicketTamer can get you through this as easily as possible, and at an affordable 
rate.
    </div>
  </div>},
  {key: 2, title: 'What Should I Do After I Get a DWI or DUI?', preview: <div>
    <div className="BodyGreyLeft mb16">Get a ride home and get some sleep. The next morning, take a picture of your ticket and any 
supporting information so that it is in your phone, then put it in a safe place. Review your options
and know when your court date is. Contact TicketTamer to help you get started at <a href="tel:(314) 728-
4444">(314) 728-
4444</a> or <a href="mailto:info@tickettamer.com">info@tickettamer.com</a>.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Get a ride home and get some sleep. The next morning, take a picture of your ticket and any 
supporting information so that it is in your phone, then put it in a safe place. Review your options
and know when your court date is. Contact TicketTamer to help you get started at <a href="tel:(314) 728-
4444">(314) 728-
4444</a> or <a href="mailto:info@tickettamer.com">info@tickettamer.com</a>.
    </div>
  </div>},
  {key: 3, title: 'I am Scared and Nervous. How Can TicketTamer help me?', preview: <div>
  <div className="BodyGreyLeft mb16">A DWI or DUI is a serious offense, so it is very understandable to be scared and nervous. 
TicketTamer is here to help. 
<br/><br/>

At TicketTamer, we offer a free consultation to review your ticket, listen to the facts and any 
information you can share, and discuss our strategy to get you the best possible deal
  </div>
</div>, content: <div>
  <div className="BodyGreyLeft mb16">
  A DWI or DUI is a serious offense, so it is very understandable to be scared and nervous. 
TicketTamer is here to help. 
<br/><br/>
At TicketTamer, we offer a free consultation to review your ticket, listen to the facts and any 
information you can share, and discuss our strategy to get you the best possible deal. During this 
consultation, we will discuss the details of your St. Louis County, St. Louis City, or St. Charles 
County case and begin to identify potential legal issues based upon your memory. 
<br/><br/>
Once you hire TicketTamer, the following will occur:
  <br/>
  <br/>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Entry of Appearance </strong>– You will be assigned an Attorney. Your Attorney will enter their 
appearance with the court so that the court knows they are your Attorney. This will 
permit the court to communicate with your Attorney.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Arraignment</strong> – After you are arrested, the Court is required to formally tell you the 
charges the prosecutor is bringing against you. This is also the time in which you enter a 
plea of “guilty” or “not guilty.” We will appear on your behalf so you do not have to, or 
go with you to court if the court requires your appearance.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Discovery</strong> – Your attorney will ask the prosecutor for all of the evidence his or her office 
has against you. The Prosecutor’s Office is required to give us everything they have – 
including any evidence showing that you were not impaired.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Review</strong> – Your Attorney will review and evaluate the evidence turned over during the 
discovery process. We will review the evidence the prosecutor provides, including police 
the report, dash cam video, witness statements, and breathalyzer or blood test results, and 
interview potential witnesses.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Pretrial Conference</strong> – The pretrial conference is an opportunity for your Attorney to meet 
with the judge and the prosecutor to discuss the status of the case and any pending 
matters. It is also an opportunity to address missing discovery, seek a continuance, or 
resolve any other issues.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>File Motions With the Court </strong>– Once we identify weaknesses in the case against you, we 
may file a motion with the court. Motions are basically legal requests asking the court to 
do something. For example, if the evidence indicates your blood alcohol level was 
actually within the lawful limit, we would file a motion asking the court to dismiss the 
case. This process can help speed along negotiations with the Prosecutor’s Office.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Negotiation with the Prosecutor’s Office </strong>– At this point, your Attorney will have had the 
opportunity to speak with you and any witnesses and also review, evaluate, and 
investigate discovery. Your Attorney will take their arguments to the Prosecutor and 
argue why your matter should be dismissed or reduced, given the flaws of the case. 
Depending upon the evidence, the Prosecutor will sometimes agree to dismiss or reduce 
the charges. In other circumstances, the prosecutor will refuse to budge and the case has 
to go to trial.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  <strong>Trial</strong> –At trial, the prosecutor will have to prove beyond a reasonable doubt that you were
driving while intoxicated or under the influence. TicketTamer will present a vigorous 
defense on your behalf, including witnesses to testify on your behalf, if possible, as well 
as other evidence to support your defense.
  </Card>

  This can be a long process, but TicketTamer will be with you during every step of the way. 
  </div>
</div>},
  {key: 4, title: 'Should I Just Go to Court on my Own and Plead Guilty or No Contest?', preview: <div>
    <div className="BodyGreyLeft mb16">
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    If you plead guilty to this charge, you will be sentenced by the Court. The Judge will use 
his or her discretion issuing a punishment. The punishments could include heavy fines, 
loss of license, and jail time.
  </Card>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    If you plead guilty to this charge, you will be sentenced by the Court. The Judge will use 
his or her discretion issuing a punishment. The punishments could include heavy fines, 
loss of license, and jail time.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  Pleading “No Contest” or an “Alford Plea” can result in the Judge finding you guilty 
almost immediately thereafter. A “No Contest” plea means you are admitting to all of the 
facts alleged in the complaint against you. You are forfeiting your right to a trial and to a 
defense.
  </Card>
  <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
  A DWI or DUI conviction will result in points on your license that will cause a 
suspension or revocation. A first-time DWI or DUI can be handled in a way that keeps 
this from happening.
  </Card>
    </div>
  </div>},
  {key: 5, title: 'What are the Maximum Penalties for DWI or DUI Convictions in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">A first-time DWI or DUI is generally charged as a second-degree misdemeanor, which carries a 
maximum penalty of up to 180 days in jail and a $1,000 fine. Other penalties can be assessed, 
however, including license suspension or revocation, probation, community service, ignition 
interlock, and higher insurance rates. A conviction may also impact your employment and 
education opportunities. It can even impact your ability to travel outside of the country.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    A first-time DWI or DUI is generally charged as a second-degree misdemeanor, which carries a 
maximum penalty of up to 180 days in jail and a $1,000 fine. Other penalties can be assessed, 
however, including license suspension or revocation, probation, community service, ignition 
interlock, and higher insurance rates. A conviction may also impact your employment and 
education opportunities. It can even impact your ability to travel outside of the country.
<br/><br/>
A second DWI or DUI conviction results in more significant consequences. In Missouri, if you 
are convicted of a second DWI or DUI, you must serve a mandatory 10 days in jail, with a 
maximum sentence of one year.
<br/><br/>
TicketTamer can help you avoid second offenses by fixing your first one.
    </div>
  </div>},
  {key: 6, title: 'What Happens if I Refused the Breath test?', preview: <div>
    <div className="BodyGreyLeft mb16">Your drivers’ license will be revoked for one year if you refuse a breath test.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Your drivers’ license will be revoked for one year if you refuse a breath test. 
    <br/><br/>
TicketTamer can fight this by filing a petition for review. It must be filed within 30 days of the 
notice of revocation (usually at time of arrest) in the county where you were arrested. In most 
cases, we can also get a court order allowing you to keep driving while we are waiting for the 
petition to be heard.
    </div>
  </div>},
  {key: 7, title: 'What Happens if I blew Over .08?', preview: <div>
    <div className="BodyGreyLeft mb16">If you have no alcohol related law enforcement contacts in the previous 5 years, you will face a 
30-day license suspension followed by 60 days of restricted driving privileges if you fail a 
chemical test for excessive blood alcohol. If you have a previous offense, you will face a 1-year 
revocation.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you have no alcohol related law enforcement contacts in the previous 5 years, you will face a 
30-day license suspension followed by 60 days of restricted driving privileges if you fail a 
chemical test for excessive blood alcohol. If you have a previous offense, you will face a 1-year 
revocation.
<br/><br/>
TicketTamer can help you stay on the road, but we have to work quickly. You only have 15 days
from the day of the arrest to file a request for an administrative hearing to fight this suspension or
revocation. You will be given a temporary permit to drive on until the hearing date.
    </div>
  </div>},
  {key: 8, title: 'What is the Difference Between a DWI and a DUI?', preview: <div>
    <div className="BodyGreyLeft mb16">In most states, including Missouri, a DWI and a DUI are the same thing. The terms are used 
interchangeably to reflect the operation of a vehicle while under the influence of a mind-altering 
substance.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    In most states, including Missouri, a DWI and a DUI are the same thing. The terms are used 
interchangeably to reflect the operation of a vehicle while under the influence of a mind-altering 
substance.
    </div>
  </div>},
  {key: 9, title: 'How Can I Find Information on My Case?', preview: <div>
    <div className="BodyGreyLeft mb16">If the charges against you were filed in State Court, you can find your case on Missouri’s 
CaseNet (<a href="www.courts.mo.gov" target="_blank" rel="noreferrer">www.courts.mo.gov</a>) by typing in your information name or ticket number. Municipal 
Court cases can sometimes be found at <a href="municourt.net" target="_blank" rel="noreferrer">municourt.net</a>.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If the charges against you were filed in State Court, you can find your case on Missouri’s 
CaseNet (<a href="http://www.courts.mo.gov" target="_blank" rel="noreferrer">www.courts.mo.gov</a>) by typing in your information name or ticket number. Municipal 
Court cases can sometimes be found at <a href="http://www.municourt.net" target="_blank" rel="noreferrer">municourt.net</a>.
    <br/><br/>
    TicketTamer gives you round-the-clock access to your case in our system, keeps you informed as
your case moves along, and lets you get a case update 24 or 7.
    </div>
  </div>},
  {key: 10, title: 'How Do I Set Up a Free Consultation?', preview: <div>
    <div className="BodyGreyLeft mb16">Click on this link (<a href="https://calendly.com/tickettamer/15min?month={YYYY-MM}" target="_blank" rel="noreferrer">Calendly link</a>) to schedule a free consultation with an Attorney at 
TicketTamer. You will be able to pick the day and time that works for you! If you would like to 
speak with an Attorney immediately, please call TicketTamer at <a href="tel:314-728-4444">314-728-4444</a>.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Click on this link (<a href="https://calendly.com/tickettamer/15min?month={YYYY-MM}" target="_blank" rel="noreferrer">Calendly link</a>) to schedule a free consultation with an Attorney at 
TicketTamer. You will be able to pick the day and time that works for you! If you would like to 
speak with an Attorney immediately, please call TicketTamer at <a href="tel:314-728-4444">314-728-4444</a>.
    </div>
  </div>},
];

const DUILawyers = (props) => {
  return <ServiceTemplateCascade 
  title="DWI or DUI Lawyers" 
  items={Items}
  image={DUIImg}
  url="submit-dui-ticket"
  metaDescription="Submit your minor in possession of alcohol ticket- Get your quote instantly - Schedule your free consultation"
  />;
}

export default DUILawyers;
